import { call, put } from "redux-saga/effects";

import { SagaResult } from "@sellernote/shared/src/utils/common/redux";
import { actions } from "@sellernote/shipda-kr/src/modules/loading";

/**
 * TODO: legacy떼면 이 함수 포함 이 파일 전체 삭제해야함
 */
export function createRequestSaga(type: string, request: any) {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;

  return function* (action: any): any {
    // (Optional) 요청했을때의 key를 응답에서도 사용해야할 경우 payload에 key를 전달해 사용할 수 있다.

    const key =
      action.payload && hasKey(action.payload.key) ? action.payload.key : null;

    yield put(actions.START_LOADING(type, key));
    try {
      const response = yield call(request, action.payload);
      const result: SagaResult = {
        type: SUCCESS,
        payload: response.data,
        note: action.note,
      };
      if (hasKey(key)) {
        result.key = key;
      }
      yield put(result);
    } catch (e: any) {
      const result: SagaResult = {
        type: FAILURE,
        payload: e.response ? e.response.data : e.response,
        note: action.note,
      };
      if (hasKey(key)) {
        result.key = key;
      }
      yield put(result);
    }
    yield put(actions.FINISH_LOADING(type, key));
  };
}

/**
 * key가 0일 때도 체크하기 위해서 사용
 * @param {*} key
 */
function hasKey(key?: number) {
  if (key || typeof key === "number") {
    return true;
  }

  return false;
}
