import { useRef, useState } from "react";
import LoadingBarAsRaw, { LoadingBarRef } from "react-top-loading-bar";

import { COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";

import { useCheckIsMobile } from "../../utils/common/hook";

export default function useLoadingBar() {
  const { isMobile } = useCheckIsMobile();

  const [showsLoadingBar, setShowsLoadingBar] = useState(false);

  const startLoadingBar = () => {
    setShowsLoadingBar(true);
    loadingBarRef.current?.continuousStart(0);
  };

  const endLoadingBar = () => {
    loadingBarRef.current?.complete();
  };

  const loadingBarRef = useRef<LoadingBarRef>(null);

  // 라이브러리에서는 '로딩 종료후 height를 없애는 API'를 제공하지 않으므로 state를 통해 표시/숨김 처리함
  const LoadingBar = showsLoadingBar ? (
    <LoadingBarAsRaw
      color="#4294FF"
      ref={loadingBarRef}
      height={isMobile ? 2 : 3}
      background={COLOR.grayScale_700}
      shadow={false}
      onLoaderFinished={() => setShowsLoadingBar(false)}
    />
  ) : null;

  return { LoadingBar, startLoadingBar, endLoadingBar };
}
