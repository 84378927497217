import produce from "immer";

import { ApiResponseState } from "@sellernote/shared/src/types/common/common";
import { apiResponseInitialState } from "@sellernote/shared/src/utils/common/redux";

import { actionType } from "./actions";

export interface UploadState {
  [key: string]: Partial<ApiResponseState<any>>;
}

// Reducer
const initialState = {};
export const reducer = (state = initialState, action: any) => {
  // TODO: 사용할때 draft 타입 추가
  return produce(state, (draft: any) => {
    switch (action.type) {
      case actionType.DELETE_FILE_SUCCESS: {
        draft.deleteFile = {
          data: action.payload,
          status: "SUCCESS",
        };
        break;
      }
      case actionType.DELETE_FILE_FAILURE: {
        draft.deleteFile = {
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }

      case actionType.INIT: {
        draft[action.key] = apiResponseInitialState;
        break;
      }
    }
  });
};
