import { createSlice } from "@reduxjs/toolkit";
import { takeLatest } from "redux-saga/effects";

import { createReducerWithSagaBundleForShipDa } from "@sellernote/shared/src/services/request";
import { ApiResponseState } from "@sellernote/shared/src/types/common/common";
import { FileInfo } from "@sellernote/shared/src/types/common/file";

import { loadingActions } from "../loading";

export interface FileState {
  GET_FILE_URL_LIST?: ApiResponseState<FileInfo[]>;
  DELETE_FILE?: ApiResponseState<any>;
}

const initialState: FileState = {};

const SLICE_NAME = "file";

const GET_FILE_URL_LIST = createReducerWithSagaBundleForShipDa<
  "GET_FILE_URL_LIST",
  { key: string[] },
  FileInfo[],
  FileState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "GET_FILE_URL_LIST",
  getRequestOptions: (payload) => {
    return {
      method: "patch",
      path: "/file/download",
      apiType: "BOFUL_API",
      data: payload,
    };
  },
  loadingActions,
});

const DELETE_FILE = createReducerWithSagaBundleForShipDa<
  "DELETE_FILE",
  any,
  any,
  FileState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "DELETE_FILE",
  getRequestOptions: (payload) => {
    return {
      method: "delete",
      path: "/file",
      apiType: "BOFUL_API",
      data: payload,
    };
  },
  loadingActions,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    ...GET_FILE_URL_LIST.reducerBundle,
    ...DELETE_FILE.reducerBundle,
  },
});

function* saga() {
  yield takeLatest(
    slice.actions.GET_FILE_URL_LIST.type,
    GET_FILE_URL_LIST.saga
  );
  yield takeLatest(slice.actions.DELETE_FILE.type, DELETE_FILE.saga);
}

export default slice;

export { saga };
