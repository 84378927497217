import produce from "immer";

import { ApiResponseStateWithDownload } from "@sellernote/shared/src/types/common/common";
import { getExpiredAt } from "@sellernote/shared/src/utils/common/date";
import { apiResponseInitialState } from "@sellernote/shared/src/utils/common/redux";

import { actionType } from "./actions";

const bidInvoiceTargetType = [
  "bidInvoice",
  "BLInvoice",
  "CIAndPLInvoice",
  "COInvoice",
  "etcInvoice",
  "certificate",
  "importDeclaration",
  "brn",
  "importTax",
  "purchaseOrder",
  "purchaseOrderEtc",
];

export interface DownloadState {
  [key: string]: Partial<ApiResponseStateWithDownload<any>>;
}

// Reducer
const initialState: DownloadState = {};
export const reducer = (state = initialState, action: any) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionType.GET_LINK_SUCCESS: {
        const isBidInvoiceTargetType =
          action.key === "bidInvoice" ||
          action.key === "BLInvoice" ||
          action.key === "CIAndPLInvoice" ||
          action.key === "COInvoice" ||
          action.key === "etcInvoice" ||
          action.key === "certificate" ||
          action.key === "importDeclaration" ||
          action.key === "brn" ||
          action.key === "importTax" ||
          action.key === "purchaseOrder" ||
          action.key === "purchaseOrderEtc";

        const isBidInvoiceTargetTypeString = () => {
          return bidInvoiceTargetType
            .map((type) => {
              return action.key.indexOf(type) > -1;
            })
            .includes(true);
        };

        if (isBidInvoiceTargetType || isBidInvoiceTargetTypeString()) {
          const duration = action.payload && action.payload.expirationTime;

          const data = { preSignedUrl: "" };
          data.preSignedUrl = action.payload;

          draft[action.key] = {
            data: data,
            status: "SUCCESS",
            needInitialDownload: "yes",
            expiredAt: getExpiredAt(duration - 60),
          };
        } else {
          const duration =
            action.payload.preSignedUrl &&
            action.payload.preSignedUrl.expirationTime;

          draft[action.key] = {
            data: action.payload,
            status: "SUCCESS",
            needInitialDownload: "yes",
            expiredAt: getExpiredAt(duration - 60), // buffer로 1분을 추가
          };
        }

        break;
      }

      case actionType.GET_LINK_FAILURE: {
        draft[action.key] = {
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }

      case actionType.INIT_GET_LINK: {
        draft[action.key] = apiResponseInitialState;
        break;
      }

      case actionType.UPDATE_GET_LINK_NEED_INITIAL_DOWNLOAD: {
        draft[action.payload.key].needInitialDownload = action.payload.status;
        break;
      }
    }
  });
};
